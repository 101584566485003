<br>
<div class="divflex height30px">
  <div class="tabStyle">
    <div class="tabStyleHeader">Residual Solvents&nbsp;{{(impuritiesResidualIndex+1)}}</div>
  </div>
  <div class="padleft105px margintopneg10px">
    <button mat-icon-button color="primary" (click)="confirmDeleteImpuritiesResidualSolvents()"
      matTooltip="Delete Impurities Residual Solvents {{impuritiesResidualIndex + 1}}">
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>
</div>

<mat-card class="margintop20px bordergray">
  <mat-card-content>
    <div class="flex-container marginleftneg7px padbottom10px">

      <!-- Left Side -->
      <div class="width25percent textaligncenter">
        <app-substance-selector class="textaligncenter" (selectionUpdated)="relatedSubstanceUpdated($event)"
          eventCategory="substanceRelationshipRelatedSub" placeholder="Substance Name" [hideImage]="false"
          header="Related Substance" [subuuid]="impuritiesResidualSolvents.relatedSubstanceUuid" [showMorelinks]=true>
        </app-substance-selector>
        <div class="textaligncenter font11px" *ngIf="impuritiesResidualSolvents.relatedSubstanceUnii">
          {{impuritiesResidualSolvents.relatedSubstanceUnii}}
        </div>
        <!--
        <div class="divflex">
          <div class="col marginleft50px">
            <a [routerLink]="['/substances/register/chemical']" target="_blank" matTooltip='Register New Substance'>
              <span class="font11px">Register New</span>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a [routerLink]="['/structure-search']" target="_blank" matTooltip='Structure Search'>
              <span class="font11px">Search By Structure</span>
            </a>
          </div>
        </div>
        -->
      </div>

      <!-- Right Side -->
      <div class="flex-item width75percent padleft20px padright20px">

        <div class="form-row">

          <mat-form-field class="col-3-1">
            <input matInput placeholder="Pharmaceutical Limit"
              [(ngModel)]="impuritiesResidualSolvents.pharmaceuticalLimit" name="pharmaceuticalLimit" />
          </mat-form-field>

          <mat-form-field class="col-3-1">
            <input matInput placeholder="Test Type" [(ngModel)]="impuritiesResidualSolvents.testType" name="testType" />
          </mat-form-field>

          <app-cv-input class="col-3-1" domain="IMPURITIES_LIMIT_TYPE" title="Limit Type" name="limitType"
            [model]="impuritiesResidualSolvents.limitType"
            (valueChange)="impuritiesResidualSolvents.limitType = $event">
          </app-cv-input>
        </div>

        <div class="form-row">
          <mat-form-field class="col-3-1">
            <input matInput placeholder="Limit Value" [(ngModel)]="impuritiesResidualSolvents.limitValue"
              name="limitValue" />
          </mat-form-field>

          <app-cv-input class="col-3-1" domain="PROD_UNIT" title="Unit" name="unit"
            [model]="impuritiesResidualSolvents.unit" (valueChange)="impuritiesResidualSolvents.unit = $event">
          </app-cv-input>

          <mat-form-field class="col-3-1">
            <textarea matInput placeholder="Comments" [(ngModel)]="impuritiesResidualSolvents.comments" name="comments"
              rows="1"></textarea>
          </mat-form-field>
        </div>

      </div>
      <!-- Right Side -->


    </div>

  </mat-card-content>
</mat-card>