<div class="form-row">
  <mat-form-field class="col-3-1">
    <input matInput placeholder="Test Type"
      [(ngModel)]="impuritiesTotal.testType" name="testType" />
  </mat-form-field>

  <app-cv-input class="col-5-1" domain="IMPURITIES_LIMIT_TYPE" title="Limit Type" name="limitType"
    [model]="impuritiesTotal.limitType" (valueChange)="impuritiesTotal.limitType = $event">
  </app-cv-input>

  <mat-form-field class="col-5-1">
    <input matInput placeholder="Limit Value" [(ngModel)]="impuritiesTotal.limitValue" name="limitValue" />
  </mat-form-field>

  <mat-form-field class="col-5-1">
    <input matInput placeholder="Amount Value" [(ngModel)]="impuritiesTotal.amountValue" name="amountValue" />
  </mat-form-field>

  <mat-form-field class="col-5-1">
    <textarea matInput placeholder="Comments" [(ngModel)]="impuritiesTotal.comments" name="comments"
    rows="1"></textarea>
  </mat-form-field>
</div>