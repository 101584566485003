<div class="sugar-form-container">
  <div *ngIf="sugar.$$deletedCode" class="notification-backdrop">
    Deleted&nbsp;
    <button mat-icon-button matTooltip="Undo" (click)="undoDelete()">
      <mat-icon svgIcon="undo"></mat-icon>
    </button>
  </div>
  <div class="form-row">
    <div class="delete-container">
      <button mat-icon-button matTooltip="Delete link" (click)="deleteLink()">
        <mat-icon svgIcon="delete_forever"></mat-icon>
      </button>
    </div>
    <div *ngIf = "structure">
        <img class = "structure-image" [src] = "structure"/>
    </div>
    <div class = "type">
      <app-cv-input domain = "NUCLEIC_ACID_SUGAR" title = "Sugar" (valueChange) = "updateSugar($event)" [model] = "sugar.sugar"></app-cv-input>
    </div>
    <div class = "addRemaining">
      <button mat-flat-button mat-primary matTooltip="Add remaining {{remaining.length}} sites" [disabled] = "remaining.length === 0" (click)="addRemainingSites()">
        Add remaining {{remaining.length}} sites
      </button>
    </div>

    <div class = "links">
      <div class="label">Sites</div>
      {{siteDisplay}}
      <button mat-icon-button matTooltip="Select sites from display" (click)="(openDialog())">
        <mat-icon svgIcon="edit"></mat-icon>
      </button>
    </div>
  </div>
  <app-audit-info [source] = "sugar"></app-audit-info>
</div>
