<div class="substance-selector-container">
  <app-substance-text-search [searchValue]='searchValue' [ngClass]="{'hidden': selectedSubstance}"
    (searchPerformed)="processSubstanceSearch($event)" (searchValueOut)="searchValueOutChange($event)" [eventCategory]="eventCategory" [placeholder]="placeholder">
  </app-substance-text-search>
  <div class="selected-substance" *ngIf="selectedSubstance" (mouseenter)="showOptions = true"
    (mouseleave)="showOptions = false">
    <div class="selected-substance-options" *ngIf="showOptions">
      <button mat-mini-fab (click)="editSelectedSubstance()">
        <mat-icon aria-label="Edit Selected Substance" svgIcon="edit"></mat-icon>
      </button>
      <a *ngIf="selectedSubstance.uuid" mat-mini-fab target="_blank"
        [routerLink]="['/substances', selectedSubstance.uuid]">
        <mat-icon aria-label="Open in new tab" svgIcon="open_in_new"></mat-icon>
      </a>
    </div>
    <div class="section-header">{{header}}</div>
    <img class="related-image" *ngIf="selectedSubstance.uuid" appSubstanceImage [entityId]="selectedSubstance.uuid">
    <!-- Spinner -->
    <div>
      <mat-progress-spinner strokeWidth="3" [diameter]="30" mode="indeterminate" *ngIf="loadingStructure">
      </mat-progress-spinner>
    </div>
    <div>
      <!-- {{selectedSubstance._name}} -->
      <span class='code' *ngIf="errorMessage === 'Not in database'"><br />NOT IN DATABASE</span>
    </div>
  </div>
</div>