<div class = "admin-container">
    <div class = "page-container">
        <mat-tab-group [selectedIndex] = "activeTab" (selectedTabChange)="onTabChanged($event)" class = "tab-group">
              <mat-tab>
                  <ng-template mat-tab-label>
                      Server Status
                  </ng-template>
                  <h2 class = "tab-label">Server Status</h2>
                <div class = "app-container">
                      <app-cache-summary class =  "full-width"></app-cache-summary>
                </div>
              </mat-tab>
                <mat-tab >
                <ng-template mat-tab-label>
                    User Management
                </ng-template>
                <ng-template matTabContent>
                <h2 class = "tab-label">User Management</h2>
                  <mat-card class = "app-container">
                        <app-user-management class =  "full-width"></app-user-management>
                  </mat-card>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                       Data Import
                    </ng-template>
                    <ng-template matTabContent>
                    <h2 class = "tab-label">Staged Data Import</h2>
                  <mat-card  class = "app-container">
                        <app-import-management class =  "full-width"></app-import-management>
                  </mat-card>
                </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    CV Management
                  </ng-template>
                  <h2 class = "tab-label">Controlled Vocabulary Edit</h2>
                  <mat-card class = "app-container">
                        <app-cv-management class =  "full-width"></app-cv-management>
                    </mat-card>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    Scheduled Jobs
                  </ng-template>
                  <ng-template matTabContent>
                  <h2 class = "tab-label">Scheduled Jobs</h2>
                  <div class = "app-container">
                        <app-scheduled-jobs></app-scheduled-jobs>
                    </div>
                </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                      All Files
                  </ng-template>
                  <ng-template matTabContent>
                  <h2 class = "tab-label">All Files</h2>
                <mat-card  class = "app-container">
                      <app-all-files class =  "full-width"></app-all-files>
                </mat-card>
              </ng-template>
              </mat-tab>
            <mat-tab >
                <ng-template mat-tab-label>
                 Data Management (Legacy)
                </ng-template>
                <h2 class = "tab-label">Bulk Data Import</h2>
              <mat-card class = "app-container">
                    <app-data-management class =  "full-width"></app-data-management>
              </mat-card>
            </mat-tab>
                        
              </mat-tab-group>
              
            </div>
</div>