<div class="search-container">
  <form>
    <mat-form-field [floatLabel]="'never'" class="search-form-field space-right-small" [ngClass]="source">
      <input class="search" type="text" [placeholder]="placeholder" aria-label="Search" matInput
        [formControl]="searchControl" [matAutocomplete]="substanceSearchAuto" (focus)="focused()">
      <mat-hint *ngIf="hintMessage">{{hintMessage}}</mat-hint>
      <mat-error *ngIf="searchControl.invalid">{{errorMessage}}</mat-error>
      <mat-autocomplete class="substance-select" panelWidth="auto" #substanceSearchAuto="matAutocomplete"
        (optionSelected)="substanceSearchOptionSelected($event)" (closed)="autoCompleteClosed()">
        <div *ngFor="let field of suggestionsFields">
          <div class="substance-suggestion-field"><span class='field-styling'><b>{{field['display']}}</b></span></div>
          <mat-option *ngFor="let suggestion of substanceSuggestionsGroup[field['value']]" [value]="suggestion.key">
            <div [innerHTML]="highlight(suggestion.key)" class="suggestion-styling">{{suggestion.key}}</div>
          </mat-option>
        </div>
        <pre aria-hidden="true" class="mat-option overflow-comp" id='overflow'></pre>
      </mat-autocomplete>
    </mat-form-field>
    <button aria-label="Search Button" class="search-button" mat-icon-button (click)="processSubstanceSearch()"
      [disabled]="searchControl.invalid">
      <mat-icon svgIcon="search"></mat-icon>
    </button>
    <button class="close-button" mat-icon-button (click)="deactivateSearch()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <button class="activate-search-button" mat-icon-button (click)="activateSearch()">
      <mat-icon svgIcon="search"></mat-icon>
    </button>
  </form>
</div>
