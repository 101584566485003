<input *ngIf="this.schema.widget.id ==='hidden'; else notHiddenFieldBlock"
  [attr.name]="name" type="hidden" [formControl]="control">
<ng-template #notHiddenFieldBlock>
<div class="widget form-group" [ngClass] = "schema.visibleIf ? 'indent': 'no-indent'">
    <label [attr.for]="id" class="horizontal control-label"  style = "display:inline-block; min-width:250px;margin-right:7px;">
      {{ schema.title }}
      <sup *ngIf = "schema.comments && !schema.description" ><mat-icon color="primary" style ="height: 18px;" svgIcon="help" class = "help-icon" (click) = "openModal(info, schema.comments)"></mat-icon></sup>

    </label>
    <span *ngIf="schema.description" class="formHelp">{{schema.description}}
      <sup *ngIf = "schema.comments"  ><mat-icon style ="height: 18px;" color="primary" svgIcon="help" class = "help-icon" (click) = "openModal(info, schema.comments)"></mat-icon></sup>

    </span>
    <input [name]="name" [attr.readonly]="(schema.widget.id!=='color') && schema.readOnly?true:null"
    class="text-widget.id textline-widget form-control"
    [attr.type]="!this.schema.widget.id || this.schema.widget.id === 'string' ? 'text' : this.schema.widget.id"
    [attr.id]="id"  [formControl]="control" [attr.placeholder]="schema.placeholder"
    [attr.maxLength]="schema.maxLength || null"
    [attr.minLength]="schema.minLength || null"
    [attr.required]="schema.isRequired || null"
    [attr.disabled]="(schema.widget.id=='color' && schema.readOnly)?true:null">
    <input *ngIf="(schema.widget.id==='color' && schema.readOnly)" [attr.name]="name" type="hidden" [formControl]="control">
</div>
</ng-template>