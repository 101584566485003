<br><br><br><br><br>
<mat-card class="bordergray width1140px">
  <mat-card-title>Substance Application Matching Lists</mat-card-title>
  <mat-card-content>

    <div class="padtop20px">

      <h3>Substance Ingredient Names:</h3>

      <ol>
        <div *ngFor="let names of substanceNames; let namesIndex = index">
          <li>{{names.name}}
            <span *ngIf="names.displayName === true">
              &nbsp;<b>(Preferred Term)</b>
            </span>
          </li>
        </div>
      </ol>

      <br><br>
      <div class="colorgreen font18px"><b>Applications Lists that contain Substance Ingredient Names in Product Names or
          Reference IND:</b></div>

      <br><br>
      <span class="font16px"><b>Total Applications Found: </b><span class="font18px colorred"><b>{{total}}</b></span></span>
      <br><br>
      <mat-table [dataSource]="dataSource" class="font12px bordergray">

        <ng-container matColumnDef="Num">
          <th mat-header-cell *matHeaderCellDef class="headerstyle"> Num </th>
          <td mat-cell *matCellDef="let element; let i = index" class="cellstyle">
            {{i + 1}}
          </td>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef class="headerstyle"> Action </th>
          <td mat-cell *matCellDef="let element; let i = index" class="cellstyle">
            <!-- Update and View Application Button -->
            <div class="divflex padtop5px">
              <div class="padleft15px">
                <a [routerLink]="['/application', element.id, 'edit']" target="_blank"
                  matTooltip='Edit Application Record'>
                  <mat-icon svgIcon="edit"></mat-icon>
                </a>
              </div>
              <div class="padleft10px">
                <a [routerLink]="['/application', element.id]" target="_blank"
                  matTooltip="View Application Record">
                  <mat-icon>article</mat-icon>
                </a>
              </div>
            </div>
            <!-- Auto Update Application Button -->
            <!--
            <div class="padbottom7px" *ngIf="element.exactMatchName">
              <span *ngIf="element.bdnum === '(No Substance Key)'">
                <br>
                <button mat-flat-button color="primary" [disabled]="element.isDisableButton"
                  (click)="autoUpdateApp(i, element.id, element.exactMatchBdnum)">Auto
                  Update
                </button>
                <div *ngIf="element.autoUpdateMessage"><span class="colorred">{{element.autoUpdateMessage}}</span></div>
              </span>
            </div>
            -->
          </td>
        </ng-container>

        <ng-container matColumnDef="Application Type">
          <th mat-header-cell *matHeaderCellDef class="headerstyle"> Application Type </th>
          <td mat-cell *matCellDef="let element" class="cellstyle"> {{element.appType}} </td>
        </ng-container>

        <ng-container matColumnDef="Application Number">
          <th mat-header-cell *matHeaderCellDef class="headerstyle"> Application Number </th>
          <td mat-cell *matCellDef="let element" class="cellstyle"> {{element.appNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef class="headerstyle"> Application Status </th>
          <td mat-cell *matCellDef="let element" class="cellstyle"> {{element.status}} </td>
        </ng-container>

        <ng-container matColumnDef="Application Sub Type">
          <th mat-header-cell *matHeaderCellDef class="headerstyle"> Application Sub Type </th>
          <td mat-cell *matCellDef="let element" class="cellstyle"> {{element.appSubType}} </td>
        </ng-container>

        <ng-container matColumnDef="Product Name">
          <th mat-header-cell *matHeaderCellDef class="headerstyle"> Product Name </th>
          <td mat-cell *matCellDef="let element" class="cellstyle"> {{element.productName}} </td>
        </ng-container>

        <ng-container matColumnDef="Application Substance Key">
          <th mat-header-cell *matHeaderCellDef class="headerstyle"> Application Bdnum </th>
          <td mat-cell *matCellDef="let element" class="cellstyle backgroundyellow">
            <span *ngIf="element.bdnum !== '(No Bdnum)'" class="colorpurple">
              <b>{{element.bdnum}}</b>
            </span>
            <span *ngIf="element.bdnum === '(No Bdnum)'">
              {{element.bdnum}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Exact Match">
          <th mat-header-cell *matHeaderCellDef class="headerstyle"> Substance Ingredient Names and Product Name - Exact
            Match </th>
          <td mat-cell *matCellDef="let element" class="cellstyle"> {{element.exactMatchName}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      </mat-table>
    </div>

  </mat-card-content>
</mat-card>
<br><br><br>