<h1 mat-dialog-title>{{isNew ? 'Add' : 'Edit'}} Parameter</h1>
<div mat-dialog-content>
  <div>
    <app-amount-form [substanceAmount]="subsAmount" #amountForm>
    </app-amount-form>
    <div class="actions">
      <span class="middle-fill"></span>
      <button mat-button (click)="cancel()">Cancel</button>
      <button mat-button [disabled]="!amountForm" (click)="save()">
        Save
      </button>
    </div>
  </div>
</div>
