<mat-sidenav-container [hasBackdrop]="hasBackdrop" >
  <mat-sidenav [disableClose]="false" mode="side" opened #matSideNavInstance class = "white-background">
    <mat-nav-list>
      <a mat-list-item tabindex="0" role="link" *ngFor="let property of substanceDetailsProperties" appScrollToTrigger [scrollToElementId]="property.dynamicComponentId + (property.type ? property.type : '')" (click)="handleSidenavClick(property.title)"
      vAlign="start">
        <span class="capitalized">{{property.title}}</span>
        <span class="chip" *ngIf="property.count != null">{{property.count}}</span>
        <span class="middle-fill"></span>
        <mat-icon class="nav-chevron" svgIcon="chevron_right"></mat-icon>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <button aria-label="Open Side Navigation" class="expand-sidenav mat-elevation-z4" (click)="openSideNav()">
      <mat-icon svgIcon="chevron_right"></mat-icon>
    </button>
    <div class="side-nav-content">
      <div class="substance-details" *ngIf="substance">
        <div class="title-container">
          <h1 *ngIf = "source !== 'staging'" class = "substance-title" [innerHTML] = "substance._nameHTML ? substance._nameHTML : substance._name"></h1>
          <span  *ngIf = "source == 'staging'">
            <h2 class = "approvalIDColor">Staging Area Record</h2>
              <h1 class = "substance-title" [innerHTML] = "substance._nameHTML ? substance._nameHTML : substance._name"></h1>

          </span>

          <span class = "middle-fill"></span>
          <div *ngIf = "substance.approvalID" class = "approvalID">{{substance.approvalID}}</div>
        </div>
        <mat-accordion multi="true">
          <mat-expansion-panel expanded="true" *ngFor="let property of substanceDetailsProperties" [id]="property.dynamicComponentId + (property.type ? property.type : '')" appScrollToTarget>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h2>{{property.title}}</h2>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div #dynamicComponent></div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
