<div class="divflex" *ngIf="loadedComponents">
  <!-- If in ginas.conf file (play framework), ix.gsrs.fda.applications.display.appToMatch = true,
     display checkbox in the top of Browse Substance page-->
  <div *ngIf="isAdmin && loadedComponents && loadedComponents.applications">
    <div *ngIf="displayMatchApplicationConfig === true">
      <mat-checkbox [checked]="displayMatchAppCheckBoxValue" (change)="setDisplayAppToMatchSession($event.checked) ">
        <span class="font14px"><b>Display Match Application Names</b></span>
      </mat-checkbox>
    </div>
  </div>

  <!-- Export Buttons -->
    <div class="padleft10px divheight" *ngIf="isAdmin && loadedComponents && loadedComponents.applications && additionalExportOptions.indexOf('appxlsx') > -1">
      <button matTooltip="Export All Applications for Substance Search Results" (click)="export('app')" mat-button
        class="mat-button export-button mat-raised-button">
        <mat-icon svgIcon="get_app"></mat-icon> Applications
      </button>
    </div>

    <div class="padleft10px divheight" *ngIf="isAdmin && loadedComponents && loadedComponents.products && additionalExportOptions.indexOf('prodxlsx') > -1">
      <button matTooltip="Export All Products for Substance Search Results" mat-button (click)="export('prod')"
        class="mat-button export-button mat-raised-button">
        <mat-icon svgIcon="get_app"></mat-icon> Products
      </button>
    </div>

  <div class="padleft10px" *ngIf="isAdmin && loadedComponents && loadedComponents.clinicaltrials && 
  additionalExportOptions.indexOf('ctusxlsx') > -1">
    <button matTooltip="Export All Clinical Trials US for Substance Search Results" mat-button
      (click)="export('clinicaltrialsus')" class="mat-button export-button mat-raised-button">
      <mat-icon svgIcon="get_app"></mat-icon> Clinical Trials US
    </button>
  </div>

  <div class="padleft10px" *ngIf="isAdmin && loadedComponents && loadedComponents.clinicaltrials && 
  additionalExportOptions.indexOf('cteuxlsx') > -1">
    <button matTooltip="Export All Clinical Trials Europe for Substance Search Results" mat-button
      (click)="export('clinicaltrialseurope')" class="mat-button export-button mat-raised-button">
      <mat-icon svgIcon="get_app"></mat-icon> Clinical Trials EU
    </button>
  </div>


  <div class="padleft10px padtop10px"
    *ngIf="((isAdmin && hasAdditionalDownloads) && (loadedComponents.applications || loadedComponents.products))">
    <mat-icon class="colorgreen"
      matTooltip="It may take some time to download. After the download process starts, you can move away
      from the download process page and continue with your work. Go to 'User Downloads' menu in Profile to get the downloaded file.">help
    </mat-icon>
  </div>

</div>