
<div mat-dialog-title class = "mat-dialog-title class" *ngIf = "(!vocab || asDialog) && !adminPanel">
    <h4 style = "margin: 0px; margin-top:-20px">Add Fragment to CV</h4>
</div>
<div mat-dialog-title class = "mat-dialog-title class" *ngIf = "adminPanel">
    <h4 style = "margin: 0px">Fragment Structure Wizard</h4>
</div>
<div >
    <div  class = 'form-row' *ngIf = "(!vocab || asDialog) && !adminPanel">
      <mat-form-field class="value cell">
        <input matInput placeholder="Value" [(ngModel)]="privateTerm.value" required name="value" />
      </mat-form-field>
      <mat-form-field class="display cell">
        <input matInput placeholder="display" [(ngModel)]="privateTerm.display" required name="display" />
      </mat-form-field>
      <mat-form-field class="description cell">
        <input matInput placeholder="description" [(ngModel)]="privateTerm.description" required name="description" />
      </mat-form-field>

    <div class = "str-column">
            <div>
                            <mat-form-field class="group cell">
                                            <input matInput placeholder="Simplified Structure" [(ngModel)]="privateTerm.simplifiedStructure"
                                            (input)= "checkImg(term)"  name="simplifiedStructure" />
                                    </mat-form-field>
            </div>
            <div  *ngIf = "privateTerm.simpleSrc">
                            <img class = "structure-img"  [src] = "privateTerm.simpleSrc"/>
            </div>
            
    </div>
    <div class = "str-column">
            <div>
                            <mat-form-field class="group cell">
                                            <input matInput placeholder="Fragment Structure" [(ngModel)]="privateTerm.fragmentStructure"
                                            (input)= "checkImg(term)"  name="fragmentStructure" />                                 
                                    </mat-form-field>
            </div>
            <div *ngIf = "privateTerm.fragmentSrc">
                            <img class = "structure-img" [src] = "privateTerm.fragmentSrc" />
            </div>
    
    </div>


  </div>
<div class="editor-container">
    <app-structure-editor (editorOnLoad)="editorOnLoad($event)" (loadedMolfile)="molvecUpdate($event)"></app-structure-editor>
    
  </div>
  <div class = "form-row"><div class = "middle-fill"></div><button mat-button (click) = "getFragmentCV()">Choose Connection Points</button></div>
  <div class = "form-row" *ngIf = "!vocab">
    <div *ngFor = "let domain of domains">
        <button mat-button (click) = "fragmentType(domain.domain)">{{domain.domain}}</button>
    </div>
  </div>

  
<div class = "form-row" [ngStyle] ="{height: !privateTerm.fragmentStructure || privateTerm.fragmentStructure === '' ? '95px':'1px'}">
    <div *ngFor = "let form of forms" >
        <a (click) = "setTermStructure(form.value)"><img class = "structure-img" src = "{{form.url}}" /></a>
    </div>
</div>
<div class = "form-row" style = "width:100%;margin-top:20px" *ngIf = "forms.length > 0 && adminPanel">
    <div >Click on a form to set the fragment term structure</div>
</div>
<div class = "form-row" style = "width:100%;margin-top:20px" *ngIf = "(!forms || forms.length <= 0) && adminPanel">
  <div >Set Connection points using  "*" atoms in the periodic table element selector.</div>
</div>


  </div>
  <div mat-dialog-actions  *ngIf = "(!vocab || asDialog) && !adminPanel">
      <div class = 'validation' *ngIf = "validationMessages.length > 0"><div><b>CV not valid:</b></div>
      <div class = 'message' *ngFor = "let message of validationMessages"><span class = "{{message.messageType}} type">{{message.messageType}} :</span>{{message.message}}</div>
      
      </div>
    <div class="actions form-row">
        <div class = 'message-field'>{{message}}</div>
      <span class="middle-fill"></span>
      <button mat-button (click) = "close()">Cancel</button>
      {{privateTerm.fragmentStructure}}-{{privateTerm.description }}--{{privateTerm.value}}

      <button mat-button (click) = "save()" [disabled]="!privateTerm.fragmentStructure || !privateTerm.description || privateTerm.value === ''">
        Save
      </button>
    </div>
  </div>
  <div mat-dialog-actions  *ngIf = "adminPanel">
    
      <div class="actions form-row">
          <div class = 'message-field'>{{message}}</div>
        <span class="middle-fill"></span>
        <button mat-button (click) = "close()">Cancel</button>
        
      </div>
    </div>