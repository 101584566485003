<br>
<div class="divflex height30px">
  <div class="tabStyle">
    <div class="tabStyleHeader">Impurities&nbsp;{{(impuritiesDetailsIndex+1)}}</div>
  </div>
  <div class="padleft105px margintopneg10px">
    <button mat-icon-button color="primary" (click)="confirmDeleteImpuritiesDetails()"
      matTooltip="Delete Impurities {{impuritiesDetailsIndex + 1}}">
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>
</div>

<mat-card class="margintop20px bordergray">
  <mat-card-content>
    <div class="flex-container marginleftneg7px padbottom10px">

      <!-- Left Side -->
      <div class="width25percent textaligncenter">
        <app-substance-selector class="textaligncenter" (selectionUpdated)="relatedSubstanceUpdated($event)"
          eventCategory="substanceRelationshipRelatedSub" placeholder="Substance Name" [hideImage]="false"
          header="Related Substance" [subuuid]="impuritiesDetails.relatedSubstanceUuid" [showMorelinks]=true>
        </app-substance-selector>
        <div class="textaligncenter font11px colororange" *ngIf="impuritiesDetails.relatedSubstanceUnii">
          {{impuritiesDetails.relatedSubstanceUnii}}
        </div>
        <!--
        <div class="divflex">
          <div class="col marginleft50px">
            <a [routerLink]="['/substances/register/chemical']" target="_blank" matTooltip='Register New Substance'>
              <span class="font11px">Register New</span>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a [routerLink]="['/structure-search']" target="_blank" matTooltip='Structure Search'>
              <span class="font11px">Search By Structure</span>
            </a>
          </div>
        </div>
        -->
      </div>

      <!-- Right Side -->
      <div class="flex-item width75percent padleft20px padright20px">

        <div class="form-row">
          <mat-form-field class="col-3-1">
            <input matInput placeholder="Source Impurity Name" [(ngModel)]="impuritiesDetails.sourceImpurityName"
              name="sourceImpurityName" />
          </mat-form-field>

          <app-cv-input class="col-3-1" domain="IMPURITIES_IMPURITY_TYPE" title="Impurity Type" name="impurityType"
            [model]="impuritiesDetails.impurityType" (valueChange)="impuritiesDetails.impurityType = $event">
          </app-cv-input>

          <mat-form-field class="col-3-1">
            <textarea matInput placeholder="Comments" [(ngModel)]="impuritiesDetails.comments" name="comments"
              rows="1"></textarea>
          </mat-form-field>
          <!--
          <app-cv-input class="col-3-1" domain="IMPURITIES_TEST_TYPE" title="Test Type" name="testType"
            [model]="impuritiesDetails.testType" (valueChange)="impuritiesDetails.testType = $event">
          </app-cv-input>
          -->
        </div>

        <div class="form-row">
          <app-cv-input class="col-3-1" domain="IMPURITIES_LIMIT_TYPE" title="Limit Type" name="limitType"
            [model]="impuritiesDetails.limitType" (valueChange)="impuritiesDetails.limitType = $event">
          </app-cv-input>

          <mat-form-field class="col-3-1">
            <input matInput placeholder="Limit Value" [(ngModel)]="impuritiesDetails.limitValue" name="limitValue" />
          </mat-form-field>

          <app-cv-input class="col-3-1" domain="PROD_UNIT" title="Unit" name="unit" [model]="impuritiesDetails.unit"
            (valueChange)="impuritiesDetails.unit = $event">
          </app-cv-input>
        </div>


        <!-- Identity Criteria -->
        <div class="margintop10px marginleftneg7px">

          <div class="padbottom10px marginright10px">
            <button mat-raised-button color="primary" (click)="addNewIdentityCriteria()"
              matTooltip="Add Identity Criteria">
              <!--
              <mat-icon svgIcon="add_circle_outline"></mat-icon>
              -->
              Add Identity Criteria
            </button>
          </div>

          <div class="">
            <div class="marginbottom10px"
              *ngFor="let identityCriteria of impuritiesDetails.identityCriteriaList; let identityCriteriaIndex = index">

              <div class="form-row padleft7px">
                <app-cv-input class="col-3-1" domain="IMPURITIES_IDENTITY_CRITERIA_TYPE" title="Identity Criteria Type"
                  name="identityCriteriaType" [model]="identityCriteria.identityCriteriaType"
                  (valueChange)="identityCriteria.identityCriteriaType = $event">
                </app-cv-input>

                <mat-form-field class="col-3-1">
                  <input matInput placeholder="Amount Value" [(ngModel)]="identityCriteria.amountValue"
                    name="amountValue" />
                </mat-form-field>

                <app-cv-input class="col-3-1" domain="PROD_UNIT" title="Unit" name="unit"
                  [model]="identityCriteria.unit" (valueChange)="identityCriteria.unit = $event">
                </app-cv-input>

                <div class="col-3-1 marginleftneg20px">
                  <button mat-icon-button color="primary"
                    (click)="confirmDeleteIdentityCriteria(impuritiesDetailsIndex, identityCriteriaIndex)"
                    matTooltip="Delete Identity Criteria {{identityCriteriaIndex + 1}}">
                    <mat-icon>delete_forever</mat-icon>
                  </button>
                </div>
              </div>
            </div> <!-- Loop -->

          </div>

        </div>

      </div>
      <!-- Right Side -->


    </div>

  </mat-card-content>
</mat-card>