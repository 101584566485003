<div mat-dialog-content>
  <div class="structure-image-content">
    <button mat-mini-fab (click)="dismissDialog()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <br><br><br>
    <app-ssg4m-scheme-view (tabSelectedIndexOut)="tabSelectedIndexOutChange($event)"
    [showProcessIndex]="showProcessIndex" [showSiteIndex]="showSiteIndex" [showStageIndex]="showStageIndex">
    </app-ssg4m-scheme-view>
  </div>
</div>