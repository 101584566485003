<div class = "dialog">

  <div mat-dialog-title>
    <h1 >Switch Alternative and Primary Definitions</h1>
  </div>
  <div mat-dialog-content class = 'dialog-content'>
<div [innerHTML] = "text" class = "message"></div>
    <div class = "dialog-body">
        <div class = "code" *ngIf = 'error !== ""'>{{error}}</div>
      <div *ngIf = "currentAlts && currentAlts.length > 0 && showButtons" class = "substances-container">
        <div *ngFor = "let c of currentAlts" class = "button-content">
          <button  mat-button (click) ="tempPrimeChange(c.refuuid)" class = "mat-flat-button">
            <div class = 'text-center thumb-col'>
              <img class = 'struc-thumb img-responsive subref' appSubstanceImage [entityId]="c.refuuid" />
            </div>
          </button>
            <div class = 'label'> 
              {{c.linkingID}} 
            </div>
            <div>
              <a  [routerLink]= "['/substances', c.refuuid]" target="_blank">
                View in new tab
                <mat-icon class = "new-icon" mat svgIcon="open_in_new"></mat-icon>
              </a>
            </div>
          </div>
      </div>     
    </div>
    <div class = "spinner-container">
      <mat-progress-bar *ngIf = "loading === true"
                        class = "top-bar"
                        color="primary"
                        mode="indeterminate"
      >
      </mat-progress-bar>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button color="primary" matDialogClose [disabled] = "loading === true">Close</button>

    <span class="middle-fill"></span>

  </div>
</div>
