<h1 mat-dialog-title>Reuse Substance References</h1>
<div mat-dialog-content>
<div class = "form-row">
    <mat-checkbox matTooltip="Apply" (change)="filterRefs()"
    [checked]="filter">Filter Out System / Validation references
  </mat-checkbox>

</div>
  <table mat-table [dataSource]="substanceReferences">

    <ng-container matColumnDef="apply">
      <th mat-header-cell *matHeaderCellDef> Apply </th>
      <td mat-cell *matCellDef="let reference">
        <mat-checkbox matTooltip="Apply" (change)="toggleReference($event, reference)"
          [checked]="domainReferenceUuids.indexOf(reference.uuid) > -1">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let reference"> {{documentTypes[reference.docType] && documentTypes[reference.docType].value}} </td>
    </ng-container>

    <ng-container matColumnDef="citation">
      <th mat-header-cell *matHeaderCellDef> Citation </th>
      <td mat-cell *matCellDef="let reference">
        <span *ngIf="!reference.url">{{reference.citation}}</span>
        <a [href]="reference.url" target="_blank" *ngIf="reference.url">{{reference.citation}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="publicDomain">
      <th mat-header-cell *matHeaderCellDef> Public Domain </th>
      <td mat-cell *matCellDef="let reference">
        {{reference.publicDomain ? 'Yes' : 'No'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="access">
      <th mat-header-cell *matHeaderCellDef> Access </th>
      <td mat-cell *matCellDef="let reference">
        <span *ngIf="!reference.access || reference.access.length === 0">
          Public
        </span>
        <span *ngIf="reference.access && reference.access.length > 0">
          <span *ngFor="let item of reference.access; last as isLast">
            {{item}}
            <span *ngIf="!isLast">, </span>
          </span>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div matDialogActions>
  <span class="middle-fill"></span>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button (click)="save()">
    Save
  </button>
</div>