<div class="counts-container" *ngIf="searchCount && loadedComponents">
  <div class="counts-column" *ngIf="loadedComponents && loadedComponents.applications">
    <div>
      Application Count:
    </div>
    <div>
      <span *ngIf="searchCount.appCountConcat != '0'; else noRecordApp">
        <div class="divflex">
          <div>
            <a [routerLink]="['/substances', substanceId]" target="_blank"
              title="View Application Records (opens in new window)">
              <div innerHTML="{{searchCount.appCountConcat}}"></div>
            </a>
          </div>
          <div>
            <mat-icon class="small-icon margintopneg10" svgIcon="open_in_new" matTooltip="opens in new window">
            </mat-icon>
          </div>
        </div>
      </span>
      <ng-template #noRecordApp>
        {{searchCount.appCountConcat}}
      </ng-template>
    </div>
  </div>

  <div class="counts-column" *ngIf="loadedComponents && loadedComponents.products">
    <div>
      Product Count:
    </div>
    <div>
      <span *ngIf="searchCount.prodCountConcat != '0'; else noRecordProd">
        <div class="divflex">
          <div>
            <a [routerLink]="['/substances', substanceId]" target="_blank"
              title="View Product Records (opens in new window)">
              <div innerHTML="{{searchCount.prodCountConcat}}"></div>
            </a>
          </div>
          <div>
            <mat-icon class="small-icon" svgIcon="open_in_new" matTooltip="opens in new window"></mat-icon>
          </div>
        </div>
      </span>
      <ng-template #noRecordProd>
        {{searchCount.prodCountConcat}}
      </ng-template>
    </div>

    <!-- Don't need it. Already Added above
    <div>
      <span>Active: </span>
      <span *ngIf="searchCount.prodActiveCount > 0; else noRecordProdActive">
        <a [routerLink]="['/substances', substanceId]" target="_blank"
          title="View Active Product Records (opens in new window)">
          <span>{{searchCount.prodActiveCount}}</span>
        </a>
        <mat-icon class="small-icon" svgIcon="open_in_new" matTooltip="opens in new window"></mat-icon>
      </span>
      <ng-template #noRecordProdActive>
        {{searchCount.prodActiveCount}}
      </ng-template>
    </div>
    <div>
      <span>Inactive: </span>
      <span *ngIf="searchCount.prodInactiveCount > 0; else noRecordProdInactive">
        <a [routerLink]="['/substances', substanceId]" target="_blank"
          title="View Inactive Product Records (opens in new window)">
          <span>{{searchCount.prodInactiveCount}}</span>
        </a>
        <mat-icon class="small-icon" svgIcon="open_in_new" matTooltip="opens in new window"></mat-icon>
      </span>
      <ng-template #noRecordProdInactive>
        {{searchCount.prodInactiveCount}}
      </ng-template>
    </div>
    -->
  </div>

  <div class="counts-column" *ngIf="loadedComponents && loadedComponents.clinicaltrials">
    <div>
      Clinical Trial Count:
    </div>
    <div>
      <span *ngIf="searchCount.clinicalCount > 0; else noRecordClinical">
        <a [routerLink]="['/substances', substanceId]" target="_blank"
          title="View Clinical Trial Records (opens in new window)">
          <span>{{searchCount.clinicalCount}}</span>
        </a>
        <mat-icon class="small-icon" svgIcon="open_in_new" matTooltip="opens in new window"></mat-icon>
      </span>
      <ng-template #noRecordClinical>
        {{searchCount.clinicalCount}}
      </ng-template>
    </div>
  </div>

  <div class="counts-column" *ngIf="loadedComponents && loadedComponents.adverseevents">
    <div>
      Adverse Event Case Count:
    </div>
    <div>
      <span *ngIf="searchCount.adverseEventCount > 0; else noRecordAdverse">
        <a [routerLink]="['/substances', substanceId]" target="_blank"
          title="View Adverse Event Records (opens in new window)">
          <span>{{searchCount.adverseEventCount}}</span>
        </a>
        <mat-icon class="small-icon" svgIcon="open_in_new" matTooltip="opens in new window"></mat-icon>
      </span>
      <ng-template #noRecordAdverse>
        {{searchCount.adverseEventCount}}
      </ng-template>
    </div>
  </div>
</div>

<!-- Application Matching List -->
<div class="divflex"
  *ngIf="this.isShowMatchList === 'true' && appMatchListCount > 0 && loadedComponents && loadedComponents.applications">
  <div>
    <br>
    <button matBadge="{{appMatchListCount}}" matBadgeColor="accent" class="button-style" (click)="launchApplicationMatchingList(substanceId)">
      Matching Application Names&nbsp;
    </button>
  </div>
  <br>
</div>