<h1 mat-dialog-title>Add Term to CV - {{vocabulary.domain}}</h1>
<div mat-dialog-content>

  <div>
    <div class = 'form-row' *ngIf = "vocabulary.domain === 'NUCLEIC_ACID_LINKAGE' || vocabulary.domain === 'NUCLEIC_ACID_SUGAR'">true
        <app-fragment-wizard></app-fragment-wizard>
    </div>
    <div  class = 'form-row'>
      <mat-form-field class="value">
        <input matInput placeholder="Value" [(ngModel)]="term.value" required name="value" />
      </mat-form-field>
      <mat-form-field class="display">
        <input matInput placeholder="display" [(ngModel)]="term.display" required name="display" />
      </mat-form-field>
      <mat-form-field class="description">
        <input matInput placeholder="description" [(ngModel)]="term.description" required name="description" />
      </mat-form-field>
    </div>

    <br/><br/>
    <div class = 'message-field'>{{message}}</div>
    <div class = 'validation' *ngIf = "validationMessages.length > 0"><div><b>CV not valid:</b></div>
    <div class = 'message' *ngFor = "let message of validationMessages"><span class = "{{message.messageType}} type">{{message.messageType}} :</span>{{message.message}}</div>
    
    </div>
    <div class="actions">
      <span class="middle-fill"></span>
      <button mat-button (click)="cancel()">Cancel</button>
      <button mat-button [disabled]="!term.description || term.value === ''" (click)="submit()">
        Save
      </button>
    </div>
  </div>
</div>
