<mat-sidenav-container autosize="true" class="margintop90px sidenav-container-size">

  <mat-sidenav #cvm mode="side" opened>
    <app-facets-manager (facetsParamsUpdated)="facetsParamsUpdated($event)" (facetsLoaded)="facetsLoaded($event)"
      [rawFacets]="rawFacets" configName="adverseeventcvm" includeFacetSearch="true">
    </app-facets-manager>
  </mat-sidenav>

  <!-- Browse Adverse Event CVM Content -->
  <mat-sidenav-content>
    <div class="side-nav-content">

      <!-- NARROW SEARCH SUGGESTIONS BEGIN -->
      <div class="narrow-search-suggestions-container"
        *ngIf="matchTypes && matchTypes.length > 0; else filterParameters">
        <div class="flex-row">
          <div class="mat-elevation-z2 narrow-search-suggestions" style="margin:auto; background-color: white;">
            <div>
              Would you like to restrict this search to a field?
            </div>&nbsp;
            <button mat-flat-button color="primary" [matMenuTriggerFor]="searchSuggestions" style="padding-right: 5px;"
              (menuOpened)="increaseOverlayZindex()" (menuClosed)="decreaseOverlayZindex()">
              <span>Fields </span>&nbsp;
              {{narrowSearchSuggestionsCount}}
              <mat-icon svgIcon="drop_down"></mat-icon>
            </button>
            <button mat-flat-button color="primary" (click)="clearFilters()">
              <span>RESET</span>
            </button>
            <button mat-icon-button color="primary" *ngIf="isSearchEditable" (click)="editAdvancedSearch()">
              <mat-icon svgIcon="edit"></mat-icon>
            </button>
            <mat-menu class="search-suggestions-container" #searchSuggestions="matMenu">
              <div class="narrow-search-dropdown" *ngFor="let matchType of matchTypes">
                <div *ngIf="((matchType) && (matchType === 'WORD' || matchType === 'FULL'))">
                  <div class="match-type">
                    {{matchType == 'WORD' ? 'Contains Match' : 'Exact Match'}}
                  </div>
                  <button mat-menu-item *ngFor="let suggestion of narrowSearchSuggestions[matchType]"
                    (click)="restricSearh(suggestion.luceneQuery)">
                    <div class="suggestion-display">
                      {{suggestion.displayField}}
                    </div>
                    <div class="suggestion-count">
                      {{suggestion.count}}
                    </div>
                  </button>
                </div>
              </div>
            </mat-menu>
          </div>
        </div>
        <div class="flex-row">
          <div class="mat-elevation-z2 narrow-search-suggestions"
            style="margin:auto; background-color: white; margin-top: 12px; ">
            For more options use the <a routerLink="/advanced-search" style="padding-left: 6px;"> Advanced Search</a>
          </div>
        </div>
      </div>
      <!-- Narrow Search Suggestion End -->

      <ng-template #filterParameters>
        <div class="search-parameters">

          <div class="mat-elevation-z2 selected-parameter" *ngIf="searchTerm">
            <div>
              <span class="capitalized font-medium-bold no-break">Search Query:</span>&nbsp;
              <span>{{searchTerm}}</span>
            </div>
            <div class="actions-container">
              <button mat-icon-button color="primary" *ngIf="isSearchEditable" (click)="editAdvancedSearch()">
                <mat-icon svgIcon="edit"></mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="clearSearch()">
                <mat-icon svgIcon="delete_forever"></mat-icon>
              </button>
            </div>
          </div>

          <!-- Display Facets selection -->
          <div class="mat-elevation-z2 selected-parameter" *ngFor="let facet of displayFacets">
            <div class='selected-container'>
              <span class="capitalized font-medium-bold selected-label">
                <mat-icon class="not-icon" svgIcon="not_interested" *ngIf="!facet.bool"></mat-icon>
                {{facet.type}}:
              </span>
              <span class="no-break selected-value">
                {{facet.val}}
              </span>
            </div>
            <div class="actions-container">
              <button mat-icon-button class="display-button" color="primary"
                (click)="facet.removeFacet(facet.type, facet.bool, facet.val)">
                <mat-icon class="display-icon" svgIcon="delete_forever"></mat-icon>
              </button>
            </div>
          </div>
          <button class="reset-facets-button" mat-raised-button color="primary" (click)="clearFilters()"
            *ngIf="(displayFacets && displayFacets.length > 0) || searchTerm">
            <span>RESET</span>
          </button>
        </div>
      </ng-template>

      <!-- Title, Export, Pagination -->
      <div class="controls-container">

        <div class="title-container">
          <span class="main-title">Browse Adverse Event CVM</span>
        </div>

        <!-- Table, Full, and Title Views -->
        <mat-button-toggle-group [value]="view" (change)="updateView($event)">
          <mat-button-toggle value="table" matTooltip="Table View">
            <mat-icon svgIcon="list"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="cards" matTooltip="Full View">
            <mat-icon svgIcon="view_stream"></mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>

        <!-- Sort By -->
        <!--
        <mat-form-field class="sort">
          <mat-label>Sort By</mat-label>
          <mat-select (selectionChange)="searchAdverseEventPt()" [(ngModel)]="order">
            <mat-option *ngFor="let option of sortValues" [value]="option.value">
              {{option.display}}
            </mat-option>
          </mat-select>
        </mat-form-field>
         -->
        <!-- Export AdverseEventCvm Button -->
        <div class="export" *ngIf="isAdmin">
          <button matTooltip="Export Search Results" mat-button (click)="export()"
            class="mat-button export-button mat-raised-button">
            <mat-icon svgIcon="get_app"></mat-icon> Export
          </button>
        </div>

        <div class="break"></div>
        <!-- Pagination -->
        <div class="full-paginator">
          <mat-paginator #paginator [length]="totalAdverseEventCvm" [pageIndex]="pageIndex" [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 50, 100]" [showFirstLastButtons]="true" (page)="changePage($event)">
          </mat-paginator>

          <div class="page-selector">
            <span class="page-label">Page: </span>
            <mat-form-field class="page-input" [ngClass]="{'bad-page':invalidPage}">
              <input matInput matTooltip="Type a page and press enter" [ngClass]="{'bad-page':invalidPage}"
                [value]="pageIndex + 1" #pagenum (keydown.enter)="customPage($event)"
                (input)="validatePageInput($event)? invalidPage = false: invalidPage = true"
                (focusout)="pagenum.value = pageIndex + 1; invalidPage = false">
            </mat-form-field>
            <span class="page-label"> of {{lastPage}}</span>
          </div>
        </div>

      </div>

      <div class="search-text">
        <!-- *** Search Any Text (TYPE AHEAD) *** -->
        <app-adverse-event-text-search class="top-search" placeholder="Search Any Text" styling="homePage"
          [searchValue]="searchValue" (searchPerformed)="processSubstanceSearch($event)"
          eventCategory="adverseEventCvmSearch">
        </app-adverse-event-text-search>

        <!-- Search Any Text Text Box -->
        <!--
        <mat-form-field class="width350px font13px">
          <input matInput placeholder="Search Any Text" [(ngModel)]="privateSearchTerm" name="privateSearchTerm"
            size="50" />
        </mat-form-field>
        <button class="activate-search-button" mat-icon-button (click)="setSearchTermValue()">
          <mat-icon svgIcon="search"></mat-icon>
        </button>
        -->
      </div>


      <!-- Display Content Details -->
      <div [ngClass]="view">

        <!-- ***** TABLE VIEW BEGIN ***** -->
        <div class="table-view">
          <table mat-table [dataSource]="adverseEventCvm" matSort (matSortChange)="sortData($event)"
            matSortActive="adverseEventCount" matSortDirection="desc" matSortDisableClear>

            <ng-container matColumnDef="adverseEvent">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Adverse Event </th>
              <td mat-cell *matCellDef="let adverse"> {{adverse.adverseEvent}} </td>
            </ng-container>

            <ng-container matColumnDef="species">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Species </th>
              <td mat-cell *matCellDef="let adverse"> {{adverse.species}} </td>
            </ng-container>

            <ng-container matColumnDef="ingredientName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ingredient Name </th>
              <td mat-cell *matCellDef="let adverse">
                <a [routerLink]="['/substances', adverse.substanceId]" target="_blank">
                  {{adverse.name}}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="adverseEventCount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Adverse Event Count </th>
              <td mat-cell *matCellDef="let adverse"> {{adverse.aeCount}} </td>
            </ng-container>

            <ng-container matColumnDef="routeOfAdmin">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Route of Administration </th>
              <td mat-cell *matCellDef="let adverse"> {{adverse.routeOfAdmin}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div> <!-- Table View END-->


        <!-- Display Content Details -->
        <div class="cards-view" *ngIf="adverseEventCvm && adverseEventCvm.length; else noRecordAdverseEvent">
          <mat-card *ngFor="let adverseEventCvm of adverseEventCvm">
            <mat-card-title>
              <div>
                <span class="font13px">Adverse Event:</span>&nbsp;
                {{adverseEventCvm.adverseEvent}}
              </div>
              <div>
              </div>
              <div>
              </div>
            </mat-card-title>

            <mat-card-content>

              <div class="row">
                <div class="row-property">
                  <div class="row-property-key">
                    Ingredient Name:
                  </div>
                  <div class="row-property-value">
                    <a [routerLink]="['/substances', adverseEventCvm.substanceId]"
                      target="_blank">{{adverseEventCvm.name}}</a>
                  </div>
                </div>

                <div class="row-property">
                  <div class="row-property-key">
                    Substance Key:
                  </div>
                  <div class="row-property-value">
                    {{adverseEventCvm.substanceKey}}
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="row-property">
                  <div class="row-property-key">
                    Species:
                  </div>
                  <div class="row-property-value">
                    {{adverseEventCvm.species}}
                  </div>
                </div>

                <div class="row-property">
                  <div class="row-property-key">
                    Route of Administration:
                  </div>
                  <div class="row-property-value">
                    {{adverseEventCvm.name}}
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="row-property">
                  <div class="row-property-key">
                    Adverse Event Count:
                  </div>
                  <div class="row-property-value">
                    {{adverseEventCvm.aeCount}}
                  </div>
                </div>
              </div>

            </mat-card-content>
          </mat-card>
        </div> <!-- class=cards -->
      </div> <!-- [ngClass]=view -->

      <!-- Pagination at the bottom of the page -->
      <div class="controls-container-right">
        <div class="full-paginator">
          <mat-paginator #paginator [length]="totalAdverseEventCvm" [pageIndex]="pageIndex" [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 50, 100]" [showFirstLastButtons]="true" (page)="changePage($event)">
          </mat-paginator>

          <div class="page-selector">
            <span class="page-label">Page: </span>
            <mat-form-field class="page-input" [ngClass]="{'bad-page':invalidPage}">
              <input matInput matTooltip="Type a page and press enter" [ngClass]="{'bad-page':invalidPage}"
                [value]="pageIndex + 1" #pagenum (keydown.enter)="customPage($event)"
                (input)="validatePageInput($event)? invalidPage = false: invalidPage = true"
                (focusout)="pagenum.value = pageIndex + 1; invalidPage = false">
            </mat-form-field>
            <span class="page-label"> of {{lastPage}}</span>
          </div>
        </div>
      </div>

      <ng-template #noRecordAdverseEvent>
        <!-- No Adverse Event Found. -->
      </ng-template>

    </div> <!-- class=side-nav-content -->
  </mat-sidenav-content>
</mat-sidenav-container>