<div>
  <div class = "label tree-label">Substance Hierarchy </div>
  <div *ngIf = "loading" class = "loading-container">
     
    <div><mat-progress-spinner
        class="spinner"
        [color]="primary"
        [diameter]="24"
        mode = "indeterminate">
    </mat-progress-spinner></div><div><i>Loading</i></div>
  </div>
  <mat-tree *ngIf = "dataSource.data && dataSource.data.length > 0 && !loading" [dataSource]="dataSource" [treeControl]="treeControl" class="hierarchy-tree">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      <li class="mat-tree-node" [ngClass] = "{'odd':node.order === 'odd', 'primary': activeNode === node}" (click)="activeNode = node" >
        <button mat-icon-button class = "tree-button" disabled [attr.aria-label]="node.text"></button>
        <div class = 'node-container nochild'>
          <div class = 'title'>
            <a class="hierarchy-link" matTooltip="view substance details" [ngClass] = "node.self ? 'current-record':''"
               [routerLink]="['/substances', node.value.refuuid || '']" attr.aria-label = "view substance for {{node.value.refPname}}" [innerHTML] = "node.value.refPname"></a>
             
            <a class = "hierarchy-edit" [routerLink]="['/substances', node.value.refuuid || '', 'edit']"
               target = "_blank"  matTooltip = "edit substance in new tab" attr.aria-label="edit substance for {{node.value.refPname}} in new tab"><span hidden>edit in new tab</span>
              <mat-icon *ngIf ="isAdmin" svgIcon="edit"  color = "primary"></mat-icon>
            </a>
          </div>
          <div class = "filler">
          </div>
          <div class = "right-container">
            <span class = 'approval'>{{node.value.approvalID? node.value.approvalID : 'PENDING'}}</span>
            <span class = "end" *ngIf = "node.relationship !== ''"> {{node.relationship}}</span>

          </div>
        </div>
      </li>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" >
      <li class = "no-border">
        <div class="mat-tree-node" [ngClass] = "{'odd':node.order === 'odd', 'primary': activeNode === node}"
             (click)="activeNode = node"  (click)="activeNode = node">


          <button mat-icon-button matTreeNodeToggle class = "tree-button"
                  [attr.aria-label]="'toggle ' + node.text">
            <mat-icon [svgIcon]="treeControl.isExpanded(node) ? 'chevron_down' : 'chevron_right'"></mat-icon>
          </button>
          <div class = 'node-container'>
            <div class = 'title'>
              <a class="hierarchy-link"  matTooltip="view substance details" [ngClass] = "node.self ? 'current-record':''"
                 [routerLink]="['/substances', node.value.refuuid || '']" [innerHTML] = "node.value.refPname"></a>
               
              <a class = "hierarchy-edit" [routerLink]="['/substances', node.value.refuuid || '', 'edit']" attr.aria-label="edit substance for {{node.value.refPname}} in new tab">
                <mat-icon *ngIf ="isAdmin" matTooltip = "edit substance in new tab" svgIcon="edit"  color = "primary"></mat-icon>
              </a>
            </div>
            <div class = "right-container">
              <span class = 'approval'>{{node.value.approvalID || 'PENDING'}}</span>
               <span class = "end" *ngIf = "node.relationship !== ''"> {{node.relationship}}</span>
             </div>
            </div>
          </div>
        <ul [class.hierarchy-tree-invisible]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>
</div>
